// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-add-location-js": () => import("./../../../src/pages/add-location.js" /* webpackChunkName: "component---src-pages-add-location-js" */),
  "component---src-pages-ambassadors-js": () => import("./../../../src/pages/ambassadors.js" /* webpackChunkName: "component---src-pages-ambassadors-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-bogo-brand-js": () => import("./../../../src/pages/bogo-brand.js" /* webpackChunkName: "component---src-pages-bogo-brand-js" */),
  "component---src-pages-bogoforbusiness-js": () => import("./../../../src/pages/bogoforbusiness.js" /* webpackChunkName: "component---src-pages-bogoforbusiness-js" */),
  "component---src-pages-business-detail-js": () => import("./../../../src/pages/business-detail.js" /* webpackChunkName: "component---src-pages-business-detail-js" */),
  "component---src-pages-confirmation-js": () => import("./../../../src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-create-campaign-js": () => import("./../../../src/pages/create-campaign.js" /* webpackChunkName: "component---src-pages-create-campaign-js" */),
  "component---src-pages-customersupport-js": () => import("./../../../src/pages/customersupport.js" /* webpackChunkName: "component---src-pages-customersupport-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-ecommerce-js": () => import("./../../../src/pages/ecommerce.js" /* webpackChunkName: "component---src-pages-ecommerce-js" */),
  "component---src-pages-entertainment-js": () => import("./../../../src/pages/entertainment.js" /* webpackChunkName: "component---src-pages-entertainment-js" */),
  "component---src-pages-fitness-js": () => import("./../../../src/pages/fitness.js" /* webpackChunkName: "component---src-pages-fitness-js" */),
  "component---src-pages-forgotpassword-js": () => import("./../../../src/pages/forgotpassword.js" /* webpackChunkName: "component---src-pages-forgotpassword-js" */),
  "component---src-pages-hotels-js": () => import("./../../../src/pages/hotels.js" /* webpackChunkName: "component---src-pages-hotels-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-influencer-register-js": () => import("./../../../src/pages/influencer-register.js" /* webpackChunkName: "component---src-pages-influencer-register-js" */),
  "component---src-pages-influencer-welcome-js": () => import("./../../../src/pages/influencer-welcome.js" /* webpackChunkName: "component---src-pages-influencer-welcome-js" */),
  "component---src-pages-influencers-js": () => import("./../../../src/pages/influencers.js" /* webpackChunkName: "component---src-pages-influencers-js" */),
  "component---src-pages-login-waiting-js": () => import("./../../../src/pages/login-waiting.js" /* webpackChunkName: "component---src-pages-login-waiting-js" */),
  "component---src-pages-merchant-confirmation-js": () => import("./../../../src/pages/merchant-confirmation.js" /* webpackChunkName: "component---src-pages-merchant-confirmation-js" */),
  "component---src-pages-merchant-registration-js": () => import("./../../../src/pages/merchant-registration.js" /* webpackChunkName: "component---src-pages-merchant-registration-js" */),
  "component---src-pages-merchant-user-js": () => import("./../../../src/pages/merchant-user.js" /* webpackChunkName: "component---src-pages-merchant-user-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-resetpassword-js": () => import("./../../../src/pages/resetpassword.js" /* webpackChunkName: "component---src-pages-resetpassword-js" */),
  "component---src-pages-restaurants-js": () => import("./../../../src/pages/restaurants.js" /* webpackChunkName: "component---src-pages-restaurants-js" */),
  "component---src-pages-retail-js": () => import("./../../../src/pages/retail.js" /* webpackChunkName: "component---src-pages-retail-js" */),
  "component---src-pages-review-js": () => import("./../../../src/pages/review.js" /* webpackChunkName: "component---src-pages-review-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-spasandsalons-js": () => import("./../../../src/pages/spasandsalons.js" /* webpackChunkName: "component---src-pages-spasandsalons-js" */),
  "component---src-pages-subscription-js": () => import("./../../../src/pages/subscription.js" /* webpackChunkName: "component---src-pages-subscription-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-user-confirmation-js": () => import("./../../../src/pages/user-confirmation.js" /* webpackChunkName: "component---src-pages-user-confirmation-js" */),
  "component---src-pages-user-login-js": () => import("./../../../src/pages/user-login.js" /* webpackChunkName: "component---src-pages-user-login-js" */),
  "component---src-pages-user-registration-js": () => import("./../../../src/pages/user-registration.js" /* webpackChunkName: "component---src-pages-user-registration-js" */),
  "component---src-pages-waiting-landing-js": () => import("./../../../src/pages/waiting-landing.js" /* webpackChunkName: "component---src-pages-waiting-landing-js" */),
  "component---src-pages-waitinglist-js": () => import("./../../../src/pages/waitinglist.js" /* webpackChunkName: "component---src-pages-waitinglist-js" */)
}

